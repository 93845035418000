import { useNavigate } from "react-router-dom";
import blurBg from "../assets/blur.png";
import upgradeOverlay from "../assets/upgrade.png";
import { useSearchParams } from "react-router-dom";

const UpgradeSection = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  const upgradeLink = teamId ? `/settings?team=${teamId}` : "/settings";

  return (
    <div className="relative mb-4">
      <img
        src={blurBg}
        alt="Insights preview"
        className="w-full h-[500px] object-cover rounded-lg blur-sm"
      />
      {/* Content overlay */}
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          src={upgradeOverlay}
          alt="Upgrade to Premium"
          className="max-w-[600px] w-full cursor-pointer hover:opacity-90 transition-opacity"
          onClick={() => navigate(upgradeLink)}
        />
      </div>
    </div>
  );
};

export default UpgradeSection;
