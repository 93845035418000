import Card from "../card";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

interface CreateEventProps {
  isPremium: boolean;
}

const CreateEvent = ({ isPremium }: CreateEventProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  const upgradeLink = teamId ? `/settings?team=${teamId}` : "/settings";

  return (
    <Card>
      <div className="flex justify-between items-center gap-x-4">
        <div>
          <h3 className="text-lg font-semibold mb-2">
            Create recurring events
          </h3>
          <div className="text-md text-gray-600">
            {isPremium
              ? "Recurring events can only be created in Slack at this time . Go to the Braid home tab and click “schedule” to set up a recurring game."
              : "Schedule games and activities in advance with Braid Premium!"}
          </div>
        </div>

        {isPremium ? (
          <button className="bg-gradient-cta text-white py-2 rounded-lg hover:bg-gradient-cta-hover transition-colors min-w-[130px]">
            Setup in Slack
          </button>
        ) : (
          <button
            className="bg-gradient-cta text-white py-2 rounded-lg hover:bg-gradient-cta-hover transition-colors min-w-[130px]"
            onClick={() => navigate(upgradeLink)}
          >
            Upgrade
          </button>
        )}
      </div>
    </Card>
  );
};

export default CreateEvent;
