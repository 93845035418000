import { useUserData } from "./useUserData";
import {
  GamesPlayedStats,
  Subscription,
  SubscriptionTier,
  Teammate,
} from "../types/user";

function generateRandomAvatar(name: string) {
  const replaced_name = name.replace(" ", "+");
  return `https://ui-avatars.com/api/?name=${replaced_name}&background=random`;
}

export function useUserProfile() {
  const {
    data: userData,
    isLoading,
    isError,
    error,
    refetch: refetchUserData,
  } = useUserData();

  const gamesPlayed: GamesPlayedStats[] =
    userData?.slack_user?.games_played || [];

  const teammatesPlayed: Teammate[] =
    userData?.slack_user?.teammates_played || [];

  const teammatesIcons =
    userData?.slack_user?.teammates_played.map(
      (teammate) => teammate.avatar_url,
    ) || [];

  const subscription: Subscription | undefined =
    userData?.slack_user?.subscription;

  const subscriptionTier: SubscriptionTier | undefined = subscription?.tier;

  const latestGameIcons: string[] = gamesPlayed
    .slice(0, 3)
    .map((game) => game.image);

  const latestTeammateIcons: string[] = teammatesPlayed.map(
    (teammate) => teammate.avatar_url || generateRandomAvatar(teammate.name),
  );

  const teammateIconsSliced = latestTeammateIcons.slice(0, 3);

  const isPremium =
    userData?.slack_user?.subscription?.tier?.tier_type === "Premium";

  return {
    isLoading,
    isError,
    isPremium,
    error,
    userData,
    subscription,
    subscriptionTier,
    latestGameIcons,
    refetchUserData,
    gamesPlayed,
    teammatesPlayed,
    teammatesIcons,
    latestTeammateIcons,
    teammateIconsSliced,
  };
}
