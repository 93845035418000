import { createContext, useContext, ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";

type TeamContext = {
  teamId: string | null;
};

const TeamContext = createContext<TeamContext>({ teamId: null });

export function TeamProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const teamId = searchParams.get("team");

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
      });
    }

    if (teamId) {
      Sentry.setTag("team_id", teamId);
    }
  }, [user, teamId]);

  return (
    <TeamContext.Provider value={{ teamId }}>{children}</TeamContext.Provider>
  );
}

export function useTeamContext() {
  return useContext(TeamContext);
}
