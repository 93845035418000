import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser, faCalendar } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { SlackInstallation } from "../types/user";

import { ChevronDown } from "lucide-react";

import { useState, useEffect } from "react";

import { useUserData } from "../hooks/useUserData";

import Logo from "../assets/braid-teams-logo.png";

// Helper to create links that preserve the team parameter
const createTeamLink = (path: string, teamId: string | null) => {
  return teamId ? `${path}?team=${teamId}` : path;
};

const navItems = [
  { to: "/profile", icon: faUser, text: "Home", disabled: false },
  {
    to: "/events",
    icon: faCalendar,
    text: "Events",
    disabled: false,
  },
  { to: "/settings", icon: faCog, text: "Settings", disabled: false },
];

const isActivePath = (path: string, pathname: string): boolean => {
  return pathname.startsWith(path);
};

interface HeaderWorkspaceSwitcherProps {
  installations: SlackInstallation[];
  currentTeamId: string | null;
}

const HeaderWorkspaceSwitcher = ({
  installations,
  currentTeamId,
}: HeaderWorkspaceSwitcherProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const currentWorkspace =
    installations.find(
      (installation) => installation.team_id === currentTeamId,
    ) || installations[0];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest("[data-switcher]")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  const handleWorkspaceChange = (teamId: string) => {
    if (teamId === currentTeamId) {
      setIsOpen(false);
      return;
    }

    // Preserve the current path when switching teams
    const currentPath = window.location.pathname;
    navigate(`${currentPath}?team=${teamId}`, { replace: true });
    setIsOpen(false);
  };

  return (
    <div className="relative ml-4" data-switcher>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 py-1 px-3 text-white rounded-lg bg-brand hover:bg-brand/80 transition-colors text-sm font-medium border border-brand/20"
      >
        <span className="truncate max-w-[140px]">
          {currentWorkspace?.team_name}
        </span>
        <ChevronDown
          size={14}
          className={`text-gray-400 transition-transform duration-200 
            ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div
          className="absolute top-full left-0 mt-1 w-48 py-1
          bg-white rounded-lg shadow-lg border border-gray-100 z-50"
        >
          {installations.map((installation) => (
            <button
              key={installation.team_id}
              onClick={() => handleWorkspaceChange(installation.team_id)}
              className={`w-full px-3 py-2 text-left text-sm
                hover:bg-brandLight transition-colors
                ${
                  currentTeamId === installation.team_id
                    ? "text-brand font-medium bg-brand/5"
                    : "text-gray-700"
                }`}
            >
              <span className="truncate block">{installation.team_name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

function Header() {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const teamId = searchParams.get("team");

  const { data: userData } = useUserData();

  return (
    <header className="bg-white shadow">
      <div className="container max-w-5xl mx-auto py-4 flex justify-between items-center px-2 lg:px-0 md:px-0">
        <div className="flex items-center">
          <img src={Logo} alt="Braid Teams Logo" className="h-8 w-auto" />
          {userData?.installations && (
            <HeaderWorkspaceSwitcher
              installations={userData.installations}
              currentTeamId={teamId}
            />
          )}
        </div>

        <nav className="font-dmSans font-bold">
          {navItems.map(({ to, icon, text, disabled }) => {
            const isActive = isActivePath(to, pathname);
            return (
              <Link
                key={to}
                to={disabled ? "#" : createTeamLink(to, teamId)}
                className={`
                  px-3 py-2 rounded-md text-sm md:text-md lg:text-md inline-flex mx-1
                  ${disabled ? "opacity-50 cursor-not-allowed" : ""}
                  ${
                    isActive
                      ? "bg-brand/10 text-brandCompliment"
                      : "text-brand hover:bg-brand hover:bg-opacity-10"
                  }
                `}
              >
                <div className="flex items-center">
                  <FontAwesomeIcon className="pr-2" icon={icon} size="lg" />
                  {text}
                </div>
              </Link>
            );
          })}
        </nav>
      </div>
    </header>
  );
}

export default Header;
