// pages/events.tsx
import Card from "../components/card";

import { SlackUser } from "../types/user";

import { useUserProfile } from "../hooks/useUserProfile";

import Spinner from "../components/spinner";
import EventCard from "../components/events/eventCard";
import CreateEvent from "../components/events/createEvent";

import { Event } from "../types/event";

interface CurrentEventsProps {
  slackUser: SlackUser;
}

interface ChannelEventGroupProps {
  channelName: string;
  events: Event[];
}

const ChannelEventGroup = ({ channelName, events }: ChannelEventGroupProps) => {
  return (
    <div className="mb-6 border-2 border-gray-100 rounded-xl px-5 pt-5 pb-5 bg-white">
      <h4 className="font-semibold text-lg mb-4 text-gray-700">
        #{channelName}
      </h4>
      <div className="space-y-4">
        {events.map((event, idx) => (
          <EventCard key={idx} event={event} />
        ))}
      </div>
    </div>
  );
};

function CurrentEvents({ slackUser }: CurrentEventsProps) {
  const { events } = slackUser;

  const hasEvents = events && events.length > 0;

  // Group events by channel
  const eventsByChannel = events?.reduce(
    (acc, event) => {
      const channel = event.channel_name;
      if (!acc[channel]) {
        acc[channel] = [];
      }
      acc[channel].push(event);
      return acc;
    },
    {} as Record<string, Event[]>,
  );

  return (
    <Card>
      <div>
        <h3 className="text-lg font-semibold mb-2">Your events</h3>
        {hasEvents ? (
          <>
            <div className="text-gray-600 mb-6">
              All events you've created are shown here. You can not edit an
              event someone else created.
            </div>
            <div className="space-y-6">
              {Object.entries(eventsByChannel).map(
                ([channel, channelEvents]) => (
                  <ChannelEventGroup
                    key={channel}
                    channelName={channel}
                    events={channelEvents}
                  />
                ),
              )}
            </div>
          </>
        ) : (
          <div className="text-gray-600">
            Once you've created your first recurring event, it will show up
            below. You will only view events you've created.
          </div>
        )}
      </div>
    </Card>
  );
}

function Events() {
  const { userData, isLoading, isError, error, isPremium } = useUserProfile();

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-brandRed">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  if (!userData || !userData.slack_user) {
    return <div className="text-center mb-4">No user data available.</div>;
  }

  return (
    <div className="h-full">
      <CreateEvent isPremium={isPremium} />
      <CurrentEvents slackUser={userData.slack_user} />
    </div>
  );
}

export default Events;
