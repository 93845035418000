import { Event } from "../../types/event";
import { useState } from "react";
import Modal from "../modal";
import { useApi } from "../../hooks/useApi";
import { useUserProfile } from "../../hooks/useUserProfile";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

interface EventCardProps {
  event: Event;
}

interface DeleteEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (shouldNotify: boolean) => void;
  event: Event;
  isDeleting: boolean;
}

const DeleteEventModal = ({
  isOpen,
  onClose,
  onConfirm,
  event,
  isDeleting,
}: DeleteEventModalProps) => {
  const [shouldNotify, setShouldNotify] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="medium">
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-4">
          Are you sure you want to cancel the following recurring event?
        </h2>

        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
          <h3 className="font-medium text-gray-900 flex items-center gap-x-2">
            <img
              src={event.game_img}
              alt={event.game_name}
              className="h-4 w-4"
            />
            {event.game_name}
          </h3>
          <p className="text-sm text-gray-600">
            {event.scheduled_date} • {event.frequency}
          </p>
        </div>

        <label className="flex items-center space-x-2 mb-6 cursor-pointer">
          <input
            type="checkbox"
            checked={shouldNotify}
            onChange={(e) => setShouldNotify(e.target.checked)}
            className="rounded border-gray-300"
            disabled={isDeleting}
          />
          <span className="text-sm text-gray-600">
            Notify channel that the event has been removed
          </span>
        </label>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-brand hover:text-brand font-medium"
            disabled={isDeleting}
          >
            Nevermind
          </button>
          <button
            onClick={() => onConfirm(shouldNotify)}
            className="px-4 py-2 bg-brand text-white rounded-lg hover:bg-brandRed hover:text-white font-medium"
            disabled={isDeleting}
          >
            Yes, delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

const EventCard = ({ event }: EventCardProps) => {
  const api = useApi();
  const { refetchUserData } = useUserProfile();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (shouldNotify: boolean) => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      const response = await api.delete(`/v2/users/events/${event.id}`, {
        data: { notify_channel: shouldNotify },
      });
      if (response.status === 204) {
        toast.success("Event deleted successfully", {
          position: "bottom-center",
        });
        // Trigger refetch - updated events
        await refetchUserData();
      } else {
        toast.error("Failed to delete event", {
          position: "bottom-center",
        });
      }
    } catch (error) {
      toast.error("Failed to delete event", {
        position: "bottom-center",
      });
      Sentry.captureException(error);
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    }
  };
  return (
    <div className="flex items-center justify-between p-5 border-2 border-gray-100 rounded-lg">
      <div className="flex items-center space-x-3">
        <img src={event.game_img} alt={event.game_name} className="h-8 w-8" />
        <div>
          <h3 className="font-medium text-gray-900">{event.game_name}</h3>
          <p className="text-sm text-gray-600">
            {event.scheduled_date} • <span>{event.frequency}</span>
          </p>
        </div>
      </div>
      <button
        className="ml-4 px-3 py-1.5 text-sm font-medium border-2 border-brand text-brand hover:text-brandRed hover:border-brandRed rounded-lg transition-colors"
        onClick={() => setIsDeleteModalOpen(true)}
        disabled={isDeleting}
      >
        Delete
      </button>
      <DeleteEventModal
        isOpen={isDeleteModalOpen}
        onClose={() => !isDeleting && setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        event={event}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default EventCard;
