import SlackSignIn from "../components/auth/signin";
import Spinner from "../components/spinner";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

import WorkspaceSwitcher from "../components/workspaceSwitcher";

import { useState, useEffect, useCallback } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useUser } from "@clerk/clerk-react";

import { useUserData } from "../hooks/useUserData";
import { useApi } from "../hooks/useApi";

import Logo from "../assets/braid-teams-logo.png";

const WorkspaceHeader = () => (
  <div className="flex flex-col items-center space-y-6 mb-12">
    <img src={Logo} alt="Braid Teams" className="h-20 w-auto animate-fade-in" />
    <div className="text-center space-y-2">
      <h1 className="text-2xl font-semibold text-gray-900">Welcome back</h1>
      <p className="text-lg text-gray-600">
        Choose your team to see your insights!
      </p>
    </div>
  </div>
);

function LandingPage() {
  const { isSignedIn, isLoaded: isAuthLoaded } = useUser();
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError,
  } = useUserData();
  const [isSettingUp, setIsSettingUp] = useState(false);

  const api = useApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentTeamId = searchParams.get("team");
  const isSetup = searchParams.get("setup") === "true";

  const handleSetup = useCallback(async () => {
    setIsSettingUp(true);
    try {
      // Complete setup for sign in
      await api.post("/v2/users/setup");
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to setup account", {
        position: "bottom-center",
      });
    } finally {
      setIsSettingUp(false);
    }
  }, [api]);

  useEffect(() => {
    if (!isAuthLoaded || isUserDataLoading) return;

    const initializeUser = async () => {
      if (isSignedIn && isSetup) {
        await handleSetup();
      }

      if (!isSignedIn || !userData?.slack_user) return;

      const installations = userData.installations || [];
      if (installations.length === 0) return;

      const targetTeamId = currentTeamId || installations[0].team_id;
      navigate(`/profile?team=${targetTeamId}`, { replace: true });
    };

    initializeUser();
  }, [
    isAuthLoaded,
    isSignedIn,
    userData,
    currentTeamId,
    isSetup,
    handleSetup,
    navigate,
    isUserDataLoading,
  ]);

  // Show loading state while checking auth and user data
  if (!isAuthLoaded || isUserDataLoading || isSettingUp) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }
  // Show error state if data fetch failed
  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-brandRed">
          Something went wrong. Please try again.
        </div>
      </div>
    );
  }

  const showSignIn = !isSignedIn || !userData?.installations?.length;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-brandLight to-brand">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-1/2 -left-1/4 w-1/2 h-1/2 bg-brand/5 rounded-full blur-3xl" />
        <div className="absolute -bottom-1/2 -right-1/4 w-1/2 h-1/2 bg-brand/5 rounded-full blur-3xl" />
      </div>

      <div className="relative z-10 w-full max-w-lg px-4">
        {userData?.installations ? (
          <div className="bg-white backdrop-blur-xl rounded-2xl shadow-xl p-8 animate-fade-up">
            <WorkspaceHeader />
            <WorkspaceSwitcher
              installations={userData.installations}
              currentTeamId={currentTeamId}
            />
          </div>
        ) : (
          showSignIn && (
            <div className="bg-white backdrop-blur-xl rounded-2xl shadow-xl p-8 animate-fade-up">
              <div className="flex flex-col items-center space-y-8">
                <img src={Logo} alt="Braid Teams" className="h-20 w-auto" />
                <div className="text-center space-y-2">
                  <p className="text-lg text-gray-900">
                    Continue with Slack to see your team's insights!
                  </p>
                </div>
                <div className="w-full">
                  <SlackSignIn />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default LandingPage;
